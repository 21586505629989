import { render, staticRenderFns } from "./AirQualityOutdoor.vue?vue&type=template&id=c7626954&scoped=true&"
import script from "./AirQualityOutdoor.vue?vue&type=script&lang=ts&"
export * from "./AirQualityOutdoor.vue?vue&type=script&lang=ts&"
import style0 from "./AirQualityOutdoor.vue?vue&type=style&index=0&id=c7626954&lang=scss&scoped=true&"
import style1 from "./AirQualityOutdoor.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7626954",
  null
  
)

/* custom blocks */
import block0 from "./AirQualityOutdoor.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QIcon,QInnerLoading,QSpinner});
