


































































































































import '@/components/Vue2Leaflet';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import date from 'quasar/src/utils/date.js';;
import { i18n } from '../i18n';
const { addToDate } = date;
import CCToggle from '../components/CCToggle.vue';

@Component({
  components: {
    CCToggle,
    IconIndicator: () => import('../components/IconIndicator.vue'),
    TextIndicator: () => import('../components/TextIndicator.vue'),
  },
})
export default class AirQualityOutdoor extends Vue {
  public $route: any;
  public $router: any;
  public $q: any;
  public $store: any;

  public loading: boolean = false;
  private viewType: string = 'card';
  private analyzersWithAlerts: any = [];
  // FIXME found on https://leaflet-extras.github.io/leaflet-providers/preview/
  private mapUrl: string = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png';
  private mapZoom = 8;
  private mapCenter = [50.6679, 3.14077];
  private analyzers: any = [];
  private realtimeIntervalId: any = null;

  public async created() {
    this.refresh();
  }

  public beforeDestroy() {
    if (this.realtimeIntervalId !== null) {
      clearInterval(this.realtimeIntervalId);
      this.realtimeIntervalId = null;
    }
  }

  public ensureRefreshInterval() {
    if (this.realtimeIntervalId === null) {
      this.realtimeIntervalId = setInterval(this.refresh, 60000);
    }
  }

  private refresh() {
    this.$store.dispatch('loadAnalyzers', {
      params: {
        outdoor: true,
        static: true,
      },
    })
    .then((response: any) => {
      this.loading = false;
      this.analyzers = response;

      for (const analyzer of this.analyzers) {
        this.$store.commit('setAnalyzer', {
          analyzer,
        });
      }

    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }

  get analyzersWithLatlon() {
    return this.analyzers.filter((analyzer: any) => (analyzer.gps_lat !== null && analyzer.gps_lon != null));
  }

  private fixMapSize() {
    const map: any = this.$refs.map;
    map.mapObject.invalidateSize();
  }

  private gotoDashboardAnalyzer(analyzer: any) {
    if (analyzer !== null) {
      this.$router.push({
        name: 'airquality-outdoor-analyzer',
        params: { analyzerId: analyzer.id },
      });
    } else {
      this.$router.push({
        name: 'airquality-outdoor',
      });
    }
  }
}
